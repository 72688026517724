<template>
  <section class="compare-vechile page-content">
    <head_mobile />
    <div class="container">
      <div class="compare-vechile-container main-container">
        <div class="compare-vechile_row">
          <div class="page-title plain">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0">{{ $t('compare_plan') }} </h2>
              <div class="custom-button text-center">
                <a @click="$router.go(-1)" class="btn"> {{ $t('back') }} </a>
              </div>
            </div>
          </div>

          <div class="compare-plans-table">
            <div class="compare-plans-table_block">
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="compare-company text-center">
                          <div class="compare-company_logo">
                            <img
                              class="img-fluid"
                              :src="`${domain}${url}/img/${data.image}`"
                            />
                          </div>
                          <div class="compare-company_name">
                            <h6 class="mb-0">{{ data.policy_name }}</h6>
                            <p>
                              <template v-if="data.policy_sub_type == 1">
                                {{ $t("result_tp") }}
                              </template>
                              <template v-if="data.policy_sub_type == 2">
                                {{ $t("result_totalloss") }}
                              </template>
                              <template v-if="data.policy_sub_type == 3">
                                {{ $t("result_allrisk") }}
                              </template>
                              <template v-if="data.policy_sub_type == 4">
                                {{ $t("result_allrisk_fiftyfifty") }}
                              </template>
                            </p>
                          </div>
                          <div class="compare-company_price">
                            <h6 class="mb-0" dir="ltr">
                              $ {{ $filters.currency(data.policy_price) }}
                            </h6>
                          </div>
                          <div class="proceed">
                            <a
                              href="#"
                              class="btn"
                              @click.prevent="BuyNow(data)"
                              > {{ $t('proceed') }} </a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="compare-company text-center">
                                                    <div class="compare-company_logo">
                                                        <img src="images/compare-plans-1.png" class="img-fluid">
                                                    </div>
                                                    <div class="compare-company_name">
                                                        <h6 class="mb-0">Union Insurance</h6>
                                                        <p>Comprehensive</p>
                                                    </div>
                                                    <div class="compare-company_price">
                                                        <h6 class="mb-0">$ 1,150</h6>
                                                    </div>
                                                    <div class="proceed">
                                                        <a href="#" class="btn">Proceed</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="compare-company text-center">
                                                    <div class="compare-company_logo">
                                                        <img src="images/compare-plans-1.png" class="img-fluid">
                                                    </div>
                                                    <div class="compare-company_name">
                                                        <h6 class="mb-0">Union Insurance</h6>
                                                        <p>Comprehensive</p>
                                                    </div>
                                                    <div class="compare-company_price">
                                                        <h6 class="mb-0">$ 1,150</h6>
                                                    </div>
                                                    <div class="proceed">
                                                        <a href="#" class="btn">Proceed</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col-header">
                        <h3> {{ $t('essentials') }} </h3>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <!-- <div
                          :class="
                            data.benefits.indexOf('agency_repair') != -1
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="data.benefits.indexOf('agency_repair') != 1"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div> -->
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('agency_repair') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            // data.agency_repair && data.is_agency != 'No'
                            data.benefits.indexOf('agency_repair') != -1
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="data.benefits.indexOf('agency_repair') != -1"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('personal_accident_driver_passenger') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.benefits.indexOf('personal_accident') != -1
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="data.benefits.indexOf('personal_accident') != -1"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('medical_expenses_driver_passenger') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.benefits.indexOf('medical_expenses') != -1 ? 'check' : 'cross'
                          "
                        >
                          <img
                            v-if="data.benefits.indexOf('medical_expenses') != -1"
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('roadside_assistance') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          :class="
                            data.benefits.indexOf('roadside_assistance') != -1
                              ? 'check'
                              : 'cross'
                          "
                        >
                          <img
                            v-if="
                              data.benefits.indexOf('roadside_assistance') != -1
                            "
                            src="@/assets/images/icons/check-compare.png"
                            class="img-fluid"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/cross-compare.png"
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="check">
                                                    <img src="images/icons/check-compare.png" class="img-fluid">
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('own_damage') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p" v-if="data.policy_sub_type == 1">
                          <div class="cross">
                            <img src="@/assets/images/icons/cross-compare.png" class="img-fluid" />
                          </div>
                        </div>
                        <div class="desc-p" v-else>
                          <p v-if="data.countries">
                            <!-- {{ $t('lebanon') }} -->
                            {{ data.countries && data.countries.length != 0        ? data.countries.toString() : "None" }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="desc-p">
                                                    <p>United Arab Emirates (UAE) Only</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="desc-p">
                                                    <p>United Arab Emirates (UAE) Only</p>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('bodily_injury_limit') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p class="amount" v-if="!isNaN(data.bodily_injury_limit)" dir="ltr">
                            $ {{ $filters.currency(data.bodily_injury_limit) }}
                          </p>
                          <p class="amount" v-else>
                            {{ data.bodily_injury_limit || 0 }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('material_damage_limit') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p class="amount" dir="ltr" v-if="data.material_damage_limit">
                            $ {{ $filters.currency(data.material_damage_limit) }}
                          </p>
                          <p class="amount" dir="ltr" v-else>
                            $ {{ $filters.currency(0) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('excess_amount') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <p class="amount" v-if="!$filters.isString(data.excess_amount)" dir="ltr">$ {{ $filters.currency(data.excess_amount) || 0 }}</p><p v-else>{{ data.excess_amount || 0 }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item" v-if="!isAllTPL">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col-header">
                        <h3> {{ $t('car_coverage') }} </h3>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <template
                         v-for="benefit in data.benefit_car_coverage"
                          :key="benefit.id"
                        >
                          <div class="d-flex m-1 compare-car-extra-coverage-container">
                            
                              <i class="fa fa-check-circle fa-1-5" style="color: #21D367" aria-hidden="true" v-if="benefit.applicable === '1'"></i>
                              <i class="fa fa-times-circle fa-1-5" style="color: #BAC2DA" aria-hidden="true" v-else></i>

                              <p class="amount px-1" style="text-align: left; font-size: 13.2576px"> {{ language === 'ar' ? benefit.title_ar : capitalFirst(benefit.title) }} </p>
                            <!-- </div> -->
                          </div>
                        </template>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

               <div class="compare-plans-table_block_item" v-if="!isAllTPL">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col-header">
                        <h3> {{ $t('extra_coverage') }} </h3>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <template
                         v-for="benefit in data.extra_coverage"
                          :key="benefit.id"
                        >
                          <div class="d-flex m-1 compare-car-extra-coverage-container">
                            
                              <i class="fa fa-check-circle fa-1-5" style="color: #21D367" aria-hidden="true" v-if="benefit.applicable === '1'"></i>
                              <i class="fa fa-times-circle fa-1-5" style="color: #BAC2DA" aria-hidden="true" v-else></i>

                              <p class="amount px-1" style="text-align: left; font-size: 13.2576px"> {{ language === 'ar' ? benefit.title_ar : capitalFirst(benefit.title) }} </p>
                            <!-- </div> -->
                          </div>
                        </template>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <!-- <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('roadside_assistance') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <ul>
                            <li
                              v-for="(
                                assistance, index
                              ) in data.roadside_assistance"
                              :key="index"
                            >
                              {{ language === 'ar' ? assistance.title_ar : assistance.title }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('highlights') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="desc-p">
                          <ul>
                            <li
                              v-for="(
                                policy, index
                              ) in data.car_policy_highlight_title"
                              :key="index"
                            >
                              {{ language === 'ar' ? policy.highlight_ar : policy.highlight }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left">
                      <div class="left-table-col">
                        <p> {{ $t('special_feature') }} </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div
                          class="desc-p"
                          v-html="data.special_features"
                        ></div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div> -->

              <div class="compare-plans-table_block_item">
                <div class="compare-plans-table_block_item_row">
                  <div class="row">
                    <div class="col-lg-3 left"></div>
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in comparedData"
                      :key="index"
                    >
                      <div class="compare-plans-table_block_item_row_item">
                        <div class="proceed">
                          <a @click.prevent="BuyNow(data)" class="btn"
                            > {{ $t('proceed') }} </a
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="proceed">
                                                    <a href="#" class="btn">Proceed</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="compare-plans-table_block_item_row_item">
                                                <div class="proceed">
                                                    <a href="#" class="btn">Proceed</a>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import Qoutes from "@/core/services/car/Qoutes";
import Title from "@/layouts/reusable/Title.vue";
import head_mobile from '@/layouts/head_mobile.vue';
import CompareDetails from "@/components/Compare.vue";
import { store } from "@/store";
import Buy from "@/core/services/car/BuyNow";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  components: {
    CompareDetails,
    Title,
    head_mobile,
  },

  setup() {
    const captcha = useReCaptcha();

    const current = computed(() => {
      return store.state.steppers.stepper;
    });

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

    const comparedData = computed(() => {
      // return store.state.qoutes.comparedData;
      return JSON.parse(window.localStorage.getItem("compare_data"));
    });

    onMounted(() => {
      const data = {
        uuid: window.localStorage.getItem("fall_back_dd"),
        policyIds: JSON.parse(window.localStorage.getItem("compare_data")),
      }

      const driver_id = router.currentRoute.value.query.driver_id
      if(driver_id) data.driver_id = driver_id

      Qoutes.getComparePolicies(data);
    })

    // handleClick
    const BuyNow = async (val) => {
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");

      const date = new Date();
      const expiryDate = date.setDate(date.getDate() + 30);
      const dateExpiry = new Date(expiryDate).toISOString();
      const data = {
        value: val,
        // uuid_dd: window.localStorage.getItem("fall_back_dd"),
        // uuid_vd: window.localStorage.getItem("fall_back"),
        payment_type: 1,
        order_description: "Online Generated Quotation",
        is_amend: 0,
        policy_sales_agent_id: 1,
        original_price: val.policy_price,
        policy_price: val.policy_price,
        sale_price: val.policy_price,
        language: language.value,
        is_agency_repair: val.agency_repair || 2,
        bai_car_insurance_policy_id: val.id || val.car_insurance_id,
        expiry_date_time: dateExpiry.split("T")[0] + " 23:59:59",
        // manufacturer_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).manufacturer_id,
        // model_id: JSON.parse(window.localStorage.getItem("customer_detatils"))
        //   .model_id,
        // trim_level_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).trim_level_id,
        uuid: router.currentRoute.value.query.uuid
      };
      const driver_id = router.currentRoute.value.query.driver_id;
      if(driver_id) data.driver_id = driver_id

      if (token) {
        const result = await Buy.getQoutationReference(data);
        if (result.status == 200) {
          // router.push({ name: "SecureChecout" });
          const fullPath = router.currentRoute.value.fullPath
          const path = fullPath.split('?')
          const host = (process.env.VUE_APP_FOLDER !== '') ? `${window.location.host}/${process.env.VUE_APP_FOLDER}` : '';
          // window.location.href = `${host}/secure-checkout?${path[1]}`; // sit
          // window.location.href = `/${process.env.VUE_APP_FOLDER}/secure-checkout?${path[1]}`;// uat

          window.location.href = (process.env.NODE_ENV === 'uat')
                                      ? `/${process.env.VUE_APP_FOLDER}/secure-checkout?${path[1]}`
                                      : `${host}/secure-checkout?${path[1]}`;

        }
      }
    };

    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    function capitalFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }

    const isAllTPL = computed(() => {
      const filter = comparedData.value.filter(x => x.policy_type !== 5)
      if(filter.length > 0) return false 

      return true
    })

    return {
      capitalFirst,
      language,
      current,
      captcha,
      comparedData,
      domain,
      url,
      BuyNow,
      isAllTPL
    };
  },
});
</script>

<style>
.fa-1-5 {
font-size: 1.5em;
}
.compare-plans-table_block_item_row_item .compare-company_name h6 {
  min-height: 40px;
}
</style>